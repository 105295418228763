/*-----------------------------------------------------------------------------------*/
/*  Our Services Section
/*-----------------------------------------------------------------------------------*/
.our-services {

  .container {
    padding-top: 100px;
  }

  .section-header {
    margin-bottom: 60px;
  }

  .inner-contents {
    padding-top: 22px;
    h2 {
      color: @main_color;
      margin-bottom: 15px;
    }
  }
}

.services-list {
  padding-top: 16px;
  li {
    overflow: hidden;
    margin-bottom: 20px;
    .icon-container {
      float: left;
      width: 69px;
      height: 69px;
      margin: 0 23px 10px 0;
      border: 2px solid #c0cad2;
      color: #c0cad2;
      text-align: center;
      .theme-transition;
      i {
        display: block;
        line-height: 65px;
      }
    }
    .contents {
      padding-top: 7px;
    }

    h5 {
      font-size: 14px;
      line-height: 27px;
      font-weight: normal;
    }
  }
}

.our-services.variant-two {

  .inner-contents {
    h2 {
      color: @heading_color;
    }
    p {
      line-height: 23px;
    }
  }

  .theme-btn {
    font-size: 14px;
    color: @heading_color;
    font-weight: 600;
    border: 2px solid #8ccfcc;
    margin-top: 6px;
    padding: 9px 28px;
    &:hover {
      color: @white;
      background-color: #8ccfcc;;
    }
  }
}

.our-services.variant-three {
  background: #684c97;

  .contianer {
    padding-top: 83px;
  }

  .section-header {
    margin-bottom: 77px;
    h2.title {
      color: @white;
      font-size: 36px;
      text-transform: capitalize;
      margin-bottom: 20px;
    }

    p {
      color: #a28aca;
    }

  }

  .services-list {
    padding: 0;
    margin: 0 0 62px;
    li {
      text-align: center;
      position: relative;
      z-index: 500;
    }
    li.line {
      height: 1px;
      background-color: #846ab1;
      padding: 0;
      margin: 0 auto;
      width: 70%;
      position: relative;
      top: 35px;
      z-index: 100;
    }
  }

  .icon-container {
    float: none;
    margin: 0 auto 20px;
    color: #c9bcdf;
    border-color: #846ab1;
    background-color: #684c97;
    &:hover {
      background-color: darken(#684c97, 25%);
      border-color: darken(#684c97, 25%);
    }
  }

  h5 {
    color: #c9bcdf;
    line-height: 27px;
    text-transform: capitalize;
    padding: 0 20px;

  }

}

.our-services.variant-four {

  .container {
    padding-bottom: 100px;
  }

  .section-header {
    h2.title {
      font-size: 48px;
      margin-bottom: 10px;
    }
    p {
      line-height: 30px;
      font-size: 16px;
      color: #524b49;
    }
  }

  #owl-carousel {
    background: url("../images/service-list-bg.png") repeat-x 0 53px;
    position: relative;
    text-align: center;
    padding: 0 100px;
  }

  .item {
    text-align: center;
    margin-bottom: 0;
    display: block;
    &:hover {
      h5 {
        color: #f16543;
      }
      .icon-container {
        background: #f16543;
        i {
          color: @white;

        }
      }
    }
    .icon-container {
      float: none;
      display: inline-block;
      margin: 0 0 20px;
      background-color: #f0eeed;
      border: none;
      .square(100px);
      .border-radius(100%);
      transition: all .3s ease-in-out;
      i {
        transition: inherit;
        color: #7d8489;
        line-height: 99px;
      }

    }
    h5 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: normal;
      transition: all .3s ease-in-out;
      a {
        color: #bcbab9;
      }
    }
  }

  .services-section-carousel {
    position: relative;
    .prev, .next {
      cursor: pointer;
      //      cursor: default;
      background: url(../images/caroseul-nav.png) no-repeat #fff 0 0;
      display: block;
      width: 45px;
      height: 45px;
      position: absolute;
      top: 30px;
      z-index: 5000;
      border: 1px solid #e6e4e3;
      .border-radius(5px);
    }
    .prev {
      background-position: 17px 13px;
      left: 0;
      &:hover {
        background-position: 17px -22px;
      }
    }
    .next {
      background-position: -31px 13px;
      right: 0;
      &:hover {
        background-position: -31px -22px;
      }
    }
  }

}




