// Responsive: Landscape phone to desktop/tablet
@media (max-width: 767px) {
  /*-----------------------------------------------------------------------------------*/
  /*  Header
  /*-----------------------------------------------------------------------------------*/
  .header-wrapper {
    .contact-card {
      .phone-number,
      .email {
        display: inline-block;
      }
    }
    &.variant-two {
      .contact-card {
        text-align: center;
        float: none;
        width: auto;
        margin: 0;
        overflow: hidden;
        border-bottom: 1px solid #43494d;
        .contact-options {
          float: none;
        }

      }
      #logo {
        height: auto !important;
        #logo-image {
          height: 61px;
        }
      }
      .social_networks {
        float: none !important;
        display: inline-block !important;
        margin-right: 0 !important;
      }
      .custom-col-contact {
        border-top: 1px solid #43494d;;
      }
    }
    &.variant-one {

      .custom-col-logo {
        overflow: hidden;
      }
      .custom-arrow-nav {
        color: #000000;
        float: right;
        margin-right: 52px;
        cursor: pointer;
        padding: 18px;
        position: relative;
        z-index: 999;
        font-size: 25px;
        transition: .5s all ease-in-out;
        visibility: hidden;
        opacity: 0;
      }

      .arrow-active {
        -webkit-animation: mymove 1s; /* Chrome, Safari, Opera */
        -webkit-animation-iteration-count: 3; /* Chrome, Safari, Opera */
        animation: mymove 1s;
        animation-iteration-count: 3;
        visibility: visible;
        opacity: 1;

      }
      @-webkit-keyframes mymove {
        from {
          top: -3px;
        }
        to {
          top: 5px;
        }
      }

      @keyframes mymove {
        from {
          top: -3px;
        }
        to {
          top: 5px;
        }
      }

      #logo {
        transition: 1.5s all ease-in-out;
        float: none;
        position: relative;
        left: 50%;
        margin-left: -61px;
        text-align: left;
      }
      .close {
        left: 5px;
        color: #43494d;
      }
      .custom-col-menu {
        border-top: 1px solid #d3dae0;
      }
    }
  }

  .header-wrapper.variant-three .contact-card .contact-options .phone-number {
    border-left: none;
  }

  .close {
    position: absolute;
    left: 12px;
    color: #ffffff;
    opacity: 1;
    padding: 19px !important;

    &:focus {
      outline: none;
    }
    &:hover {
      color: #ffffff;
      opacity: .7;
    }
  }

  .wrapper-contact-options {
    margin-left: 45px;
  }

  .variant-three {

    .contact-card .contact-options {
      border-bottom: 1px solid #65bbd9;
      margin-bottom: -1px;

    }
  }

  .variant-four {
    .wrapper-contact-options {
      margin-right: 45px !important;
    }
    .contact-card .contact-options {
      margin-left: 0;
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /*  Home FlexSlider
  /*-----------------------------------------------------------------------------------*/
  .slide-description {
    display: none;
  }

  .home-slider-4 {
    .slide-description {
      padding: 0 25px !important;
      display: block;
      h2 {
        font-size: 36px !important;
      }
      p {
        display: none;
      }
      a {
        padding: 10px 30px !important;
      }
    }
  }

  .follow-us .flexslider .flex-direction-nav li a,
  .testimonial .flexslider .flex-direction-nav li a,
  .flexslider .flex-direction-nav a {
    &.flex-prev {
      left: 0;
    }
    &.flex-next {
      right: 0;
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /*  One Page portfolio
  /*-----------------------------------------------------------------------------------*/
  .our-team.variant-three {
    .team-member {
      .member-info {
        text-align: center;
      }
      p {
        padding: 0 40px;
      }
    }
  }

  .welcome-section {
    .container {
      padding-top: 0;
    }
    h1 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /* Team var-4
  /*-----------------------------------------------------------------------------------*/
  .team-box {
    padding: 0 75px;
  }

  // About
  .about-us {
    &.variant-two {
      .container {
        padding-top: 70px;
        padding-bottom: 40px;
      }
      figure {
        margin-bottom: 15px;
      }
    }
    &.variant-three {
      .section-header h2.title,
      .section-header h3 {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  .innards {
    h3 {
      margin-bottom: 17px;
    }
    .icon-container {
      margin-bottom: 30px;
    }
  }

  //Services
  .our-services-list p {
    margin-bottom: 17px;
  }

  .services-items {
    margin-bottom: 30px;
  }

  .our-services.variant-three {
    .services-list li.line {
      display: none;
    }
    .services-list li .contents {
      padding-top: 0;
      margin-bottom: 30px;
    }
  }

  //Work
  .our-work #filter-by a {
    padding: 8px 13px;
  }

  // Testimonial
  .testimonial.variant-two i.icon-quote-end,
  .testimonial.variant-two i.icon-quote-start {
    background-size: 100%;
  }

  .follow-us .flexslider li .container,
  .testimonial .flexslider li .container {
    padding-left: 70px;
    padding-right: 70px;
  }

  // Contact Detail
  .contact-details-list li {
    font-size: 14px;
    width: 150px;
  }

  .contact-details.variant-three {
    .contact-details-list li {
      float: none;
      &.fax {
        border: none;

      }
    }
  }

  // Contact
  .contact-us .contact-form {
    margin-left: 0;
    margin-right: 0;
  }

  .header-wrapper.variant-four {
    .custom-col-contact {
      border-top: 1px solid #d3dae0;
    }
    .social_networks {
      display: inline-block;
      margin-right: 0;
      float: none;
    }
    .contact-card {
      text-align: center;
      .contact-options {
        margin-left: 25px;
        float: none;
      }
    }
    .close {
      color: #929a9b;
      left: 12px;
    }
    .logo-wrapper > #logo {
      height: 61px;
      clear: both;
    }
  }

  .contact-details.variant-four {
    background-size: cover;
  }

  .contact-details.variant-four .contact-details-list li i {
    float: none;
  }

  .contact-details.variant-four .contact-details-list li span {
    float: none;
    font-size: 24px;
  }

  /*-----------------------------------------------------------------------------------*/
  /* contact us
  /*-----------------------------------------------------------------------------------*/
  .contacts-list {
    li {
      .inner-contact {
        margin-bottom: 50px;
      }
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /* font list
  /*-----------------------------------------------------------------------------------*/
  .font-list {
    li {
      &:last-child {
        border-bottom: 1px solid #DCE3E9;
      }
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /* follow us slider home 4
  /*-----------------------------------------------------------------------------------*/
  .follow-us.variant-four {
    .flexslider {
      .flex-viewport {
        ul {
          li {
            .custom-container-follow {
              max-width: 450px;
              p {
                font-size: 23px;
              }
            }
          }
        }
      }
    }

  }

  /*-----------------------------------------------------------------------------------*/
  /* tables
  /*-----------------------------------------------------------------------------------*/
  .pinned {
    border-right: none !important;
  }

  /*-----------------------------------------------------------------------------------*/
  /* elements
  /*-----------------------------------------------------------------------------------*/
  .wrapper-buttons {
    text-align: center;
    a {

    }
  }
}








