/*-----------------------------------------------------------------------------------*/
/*  Theme Core
/*-----------------------------------------------------------------------------------*/
.selection_color {
  background: #28716E;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  .selection_color;
}

::selection {
  .selection_color;
}

body {
  font-size: 13px;
  line-height: 23px;
  color: @body_color;
}

h1, h2, h3, h4, h5, h6 {
  color: @heading_color;
  margin-top: 0;
  font-weight: 700;
  a {
    color: @heading_color;
    &:hover {
      color: @anchor_color;
    }
  }
  span {
    color: @anchor_color;
  }
}

h1,
h2,
h3 {
  margin-bottom: 20px;
}

h4,
h5,
h6 {
  margin-bottom: 15px;
}

a {
  text-decoration: none !important;
  &:hover {
  }
  &:focus {
    outline: none;
  }
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 20px;
}
