/*-----------------------------------------------------------------------------------*/
/*  Our Work Section
/*-----------------------------------------------------------------------------------*/
.our-work {
  .container {
  }

  #filter-by {
    margin: 0 0 50px;
    border: 1px solid #cad3db;
    a {
      display: inline-block;
      color: #61676c;
      cursor: pointer;
      border: 1px solid transparent;
      border-right-color: #cad3db;
      padding: 12px 23px;
      margin: -1px;
      transition: .2s all ease-in-out;
      &:last-child {
        border-right: none;
      }
      &:focus,
      &.active,
      &:hover {
        outline: 0;
        background-color: @main_color;
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .gallery-item {

    .inner-contents {
      border-bottom: 1px solid #cad3db;
    }

    figure {
      position: relative;
      margin: 0 0 21px;
    }

    img {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }

    .gallery-btn {
      position: absolute;
      top: 45%;
      left: 50%;
      z-index: 200;
      width: 160px;
      margin: -22px 0 0 -79px;
      opacity: 0;
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: @white;
      border: 2px solid @white;
      &:hover {
        color: @anchor_color;
        background-color: @white;
      }

    }

    .media-container {
      position: absolute;
      z-index: 100;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: @main_color;
    }

    .item-title {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 4px;
      color: #61676c;
    }

    p {
      margin-bottom: 12px;
      a {
        color: #a1a9b0;
        margin-right: 3px;
        text-decoration: none;
        &:hover {
          color: @anchor_color;
        }
      }
    }
  }

}

.container.portfolio-response {
  padding-top: 0;
  padding-bottom: 0;
}

.projects-showcase {
  position: relative;
  border: 1px solid #c6d0d8;
  margin-bottom: 60px;
  box-sizing: border-box !important;
  .control-btns {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 999;
    background-color: @white;
    a {
      float: left;
      padding: 19px 21px;
      border-left: 1px solid #c6d0d8;
      border-bottom: 1px solid #c6d0d8;
      text-decoration: none;
      background: url("../images/slider-nav-2.png") no-repeat -87px center;
      &.prev {
        background-position: 19px center;
      }
      &.close-btn {
        background: url("../images/icon-close.png") no-repeat center center;
      }
      &:hover {
        background-color: #c6d0d8;
      }
    }
  }

  .slide-show {
    padding-left: 0;
    padding-right: 10px;
  }

  .project-details {
    padding-top: 48px;
    padding-left: 20px;
  }
  .work-4-detail {
    padding-right: 16px;
  }

  .flexslider {
    ul,
    ul li {
      padding: 0;
      margin: 0;
      border: none;
    }
    .flex-direction-nav {
      width: 90px;
      display: block;
      position: absolute;
      right: 20px;
      bottom: 15px;
      z-index: 100;
      text-indent: -99999px;
      li {
        float: left;
        padding: 1px 0;
        a {
          position: static;
          background: url("../images/slider-nav-2.png") @heading_color no-repeat 0 0;
          opacity: .8;
          &:hover {
            background-color: darken(@heading_color, 5%);
            opacity: 1;
          }
          border: none;
          width: 45px;
          height: 40px;
          margin: 0;
          &.flex-next {
            background-position: -88px center;
            border-left: 1px solid #4c5257;
          }
          &.flex-prev {
            background-position: 19px center;
          }
        }
      }
    }
  }

  .title {
    color: @anchor_color;
    font-size: 30px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  article ul, article ol {
    margin: 0 0 28px;
    padding-left: 0;

    li {
      position: relative;
      margin-left: 20px;
      padding: 3px 0 9px 0px;
      background-position: 4px 10px;
      margin-bottom: 5px;
      border-bottom: 1px solid #dce3e9;
      list-style: none;

      &:before {
        font-family: 'FontAwesome';
        content: "\f061";
        position: absolute;
        left: -20px;

      }
      &:last-child {
        border-bottom: none;
      }
    }

  }

  .theme-btn {
    color: @heading_color;
    border: 1px solid @heading_color;
    font-weight: 700;
    padding: 8px 31px 7px;
    border-width: 2px;
    font-size: 14px;
    margin-bottom: 25px;
    &:hover {
      color: @white;
      background-color: @heading_color;
    }
  }

}

.container-work-1 {
  padding-top: 80px;
  padding-bottom: 80px;
}
