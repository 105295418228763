/*-----------------------------------------------------------------------------------*/
/*  Contact us Section
/*-----------------------------------------------------------------------------------*/
.contact-us {
  background-color: @main_color;

  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-header {
    margin-bottom: 70px;
    .title {
      color: @white;
    }
    p {
      color: @white;
      font-size: 16px;
    }
  }

  .separator .line {
    background-color: #d6593b;
  }

  .contact-form {
    margin: auto 200px;

    ::-webkit-input-placeholder {
      color: @gris_fonce;
    }
    ::-moz-placeholder {
      color: @gris_fonce;
    }
    /* firefox 19+ */
    :-ms-input-placeholder {
      color: @gris_fonce;
    }
    /* ie */
    input:-moz-placeholder {
      color: @gris_fonce;
    }
    input {
      border: 1px solid transparent;
      transition: .2s all ease-in-out;
      &:focus {
        color: #ffffff;
        border-color: #ffffff;
      }

    }

    input,
    textarea {
      color: @gris_fonce;
      background-color: @white;
      height: 43px;
      padding-left: 16px;
      padding-right: 16px;
    }
    textarea {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      resize: none;
      width: 100%;
      height: 151px;
      border: 1px solid transparent;
      transition: .2s all ease-in-out;
      &:focus {
        color: #ffffff;
        border-color: #ffffff;
      }
    }
    p {
      overflow: hidden;
      text-align: center;
      margin-bottom: 28px;
    }
    #email,
    #name {
      width: 48%;
    }
    #name {
      float: left;
    }
    #email {
      float: right;
    }
    #subject {
      width: 100%;
    }
    #contact_submit {
      font-size: 14px;
      color: @white;
      background: @main_color;
      border: 2px solid @white;
      padding: 3px 33px 1px;
      float: right;
      &:hover {
        background-color: @main_color;
        border-color: @white;
      }
    }

    #contact-loader {
      display: none;
      margin: 9px 10px 0 0;
    }

    input.error,
    textarea.error {
      border: 1px solid @white;
    }
  }

  .status-wrapper {
    float: left;
  }

  .submit-and-loader {
    float: right;
  }

  .error-container {
    display: none;
    label.error {
      font-weight: 500;
      color: @white;
      display: block;
    }
  }

  #message-sent {
    display: none;
  }

}

