/*-----------------------------------------------------------------------------------*/
/*  Our Clients Section
/*-----------------------------------------------------------------------------------*/
@section_background_color : #e4eaef;
.our-clients {
  text-align: center;
  background-color: @section_background_color;

  .container {
    padding-top: 30px;
    padding-bottom: 80px;
  }

  .arrow .shape {
    border-top-color: @section_background_color;
  }

  .section-header {
    margin-bottom: 30px;
  }

  .separator {
    margin: 5px 0 6px;
  }

}

.client-section-carousel {
  padding: 0 70px;

  .owl-item .item {
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }

  .owl-controls {
    margin-top: 39px;
    .owl-page {
      span {
        border-radius: 0;
        width: 7px;
        height: 7px;
        margin: 2px 6px;
        background-color: #7c858c;
        opacity: 1;
        &:hover {
          background-color: @anchor_color;
        }
      }
      &.active,
      &:hover {
        span {
          background-color: @anchor_color;
        }
      }
    }
  }
}