/*-----------------------------------------------------------------------------------*/
/*  Testimonial Section
/*-----------------------------------------------------------------------------------*/
.testimonial {
  text-align: center;
  padding-top: 110px;
  padding-bottom: 100px;
  background-color: @main_color;
  background-attachment: fixed;
  background-size: 100%;
}

.quote {
  .icon-quote-start {
    background: url('../images/icon-quote-start.png') no-repeat 0 0;
  }

  .icon-quote-end {
    background: url('../images/icon-quote-end.png') no-repeat 0 0;
  }

  blockquote {
    border: none;
    margin: 0;
    padding: 0;
    i {
      display: inline-block;
      width: 64px;
      height: 54px;
    }
    p {
      color: @white;
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
      padding: 9px 0;
    }
    .author {
      font-size: 21px;
      line-height: 42px;
      color: #340f06;
    }
    .separator {
      margin-bottom: 2px;
      .line {
        background-color: #f2967f;
        width: 165px;
      }
      .square {
        background-color: @white;
      }
    }
  }
}