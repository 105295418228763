/*-----------------------------------------------------------------------------------*/
/*  Our Blog Section
/*-----------------------------------------------------------------------------------*/
.reply {
  a {
    color: @anchor_color;
    &:hover {
      color: @text-color;

    }
  }
}

.explore-more {
  //  text-decoration: none;
  &:hover {
    color: @main_color !important;
  }
}

.our-blog {
  background-color: @section_background_color;
  .view-more {
    background: @main_color;
    transition: all .2s ease-in-out;
    color: #ffffff;
    &:hover {
      background: @main_color;
    }
  }
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .arrow .shape {
    border-top-color: @section_background_color;
  }

  article {

    header {
      text-align: left;
    }

  }

  .hentry {

    .post-title {
      line-height: 21px;
      margin-bottom: 5px;
      color: @heading_color;
    }

    h2.post-title {
      font-size: 18px;
    }

    .post-meta {
      color: #a1a9b0;
      font-family: 'Open Sans', sans-serif;
      font-style: italic;
      margin-bottom: 14px;
      a {
        font-style: normal;
        color: @heading_color;
        &:hover {
          color: @anchor_color;
        }
      }
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
      width: 100%;
      transition: .2s all ease-in-out;
      //      transform: scale(1);

    }

    p {
      margin-bottom: 20px;
    }

  }

  .post-contents {
    padding: 20px 25px 14px;
    background-color: @white;

  }

  .post-video, .gallery-post-slider {
    margin: 0;
  }

  .post-video .video-wrapper {
    padding-bottom: 60.8%;
  }

  .arrow {
    .shape {
      border-top-color: #e5eaef;
    }
    .icon {
      //      background: url("../images/icon-arrow-down-2.png") no-repeat 50% 10px;
    }
  }

}

.post-grid-2-col {
  margin-bottom: 30px;

  p {
    line-height: 21px;
  }

  .large-col {
    .post-meta {
      margin-bottom: 13px;
    }
  }

  .small-col {

    .hentry h2.post-title {
      margin-bottom: 2px;
    }

    .post-meta {
      margin-bottom: 17px;
    }

    .post-contents {
      padding: 24px 25px 26px
    }

  }

  .post-video .video-wrapper {
    padding-bottom: 56.8%;
  }

}

.post-grid-3-col {
  article {
    margin-bottom: 30px;
  }

  .hentry h2.post-title {
    margin-bottom: 3px;
  }

  p {
    line-height: 21px;
  }

  .post-contents {
    padding-top: 23px;
  }

  .hentry .post-meta {
    margin-bottom: 19px;
  }

}