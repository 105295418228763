/*-----------------------------------------------------------------------------------*/
/*  Page Header
/*-----------------------------------------------------------------------------------*/
.page-container, .post-container {
  padding-top: 50px;
  padding-bottom: 100px;
  .page-header {
    margin: 0 0 45px;
    padding: 0;
    border: none;
    text-align: center;
  }
}

.page-header {
  img {
    margin: 25px 0;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    padding: 0 25px;
    margin-bottom: 30px;
  }
}

.page-title {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 3px;
  + .separator {
    margin: 0 0 25px;
    .line {
      width: 85px;
    }
  }
}

.title-heading {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
  + .separator {
    margin: 0 0 30px;
    .line {
      width: 85px;
    }
  }
}

