/*-----------------------------------------------------------------------------------*/
/*  Layout
/*-----------------------------------------------------------------------------------*/
.page-container {
  .box-sizing(content-box);
}

.main,
.sidebar {
  position: static;
}

.main {
  a {
    transition: all .2s ease-in-out;
    color: @anchor_color;
    &:hover {
      color: @text-color;
    }
  }
}
h3 {
  font-size: 22px;
}