/*-----------------------------------------------------------------------------------*/
/*  Our Services List Section
/*-----------------------------------------------------------------------------------*/
.our-services-list {
  text-align: center;
  color: @second_color;
  background-color: @gris_fonce;

  .container {
    padding-top: 60px;
    padding-bottom: 90px;
  }

  .icon-box {
    padding: 27px 0;
    min-height: 116px;
    height: auto !important;
    height: 116px;
  }
  .title {
    line-height: 23px;
    margin-bottom: 15px;
  }
  p {
    color: @white;
    margin-bottom: 35px;
    line-height: 23px;
  }

  .line {
    display: block;
    border-bottom: 1px solid @white;
    width: 46.5%;
    margin: 0 auto 15px;
  }
  .theme-btn {
    text-transform: uppercase;
    padding: 9px 30px;
    font-size: 14px;
    font-weight: 700;
    color: @second_color;
    border: 2px solid @second_color;
    &:hover {
      color: @white;
      background-color: @second_color;
    }
  }
  .services-items h4.title {
    font-size: 20px;
    font-weight: normal;
    color: @white;
  }
}

.our-services-list.variant-three {
  background-color: #cf5e87;

  .icon-box {
    color: #feccde;
  }
  .title {
    color: @white;
  }
  p {
    color: #efacc4;
  }

  .line {
    border-color: #aa5272;
  }

  .theme-btn {
    color: @white;
    background-color: #b55276;
    border: 2px solid #b55276;
    &:hover {
      background-color: darken(#b55276, 20%);
      border-color: darken(#b55276, 20%);
    }
  }
}

.our-services-list.variant-four {
  background-color: #f0eeed;

  .container {
    padding-top: 65px;
    padding-bottom: 60px;
  }

  .section-header {
    margin-bottom: 70px;
    p {
      line-height: 24px;
      margin: 0 0 20px;
    }
  }

  h2.title {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  h2.title,
  .title {
    color: #524b49;
  }

  .title {
    color: #524b49;
    margin-bottom: 10px;
  }

  p {
    color: #918d8b;
  }

  .services-items h4.title {
    font-size: 20px;
    font-weight: normal;
    color: @white;
  }

  .line {
    border-bottom-color: #cccaca;
  }
}



