/*-----------------------------------------------------------------------------------*/
/*  WordPress Core
/*-----------------------------------------------------------------------------------*/
.alignnone {
  margin: 5px 30px 15px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 15px 30px;
}

.alignleft {
  float: left;
  margin: 5px 30px 15px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float: right;
  margin: 5px 0 15px 30px;
}

a img.alignnone {
  margin: 5px 30px 15px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 30px 15px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: none;
  border: none;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 0px;
  text-align: center;
}

.gallery-caption {
  font-size: 11px;
  line-height: 18px;
  padding: 5px 0;
}

.wp-caption.alignnone {
  margin: 5px 30px 15px 0;
}

.wp-caption.alignleft {
  margin: 5px 30px 15px 0;
}

.wp-caption.alignright {
  margin: 5px 0 15px 30px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding: 10px 8px;
}

.alert-wrapper {
  margin-bottom: 80px;
}

/*-----------------------------------------------------------------------------------*/
/*  WP Gallery
/*-----------------------------------------------------------------------------------*/
article #gallery-1 {
  margin-left: -15px;
  margin-right: -15px;
}

.gallery dl.gallery-item {
  padding-top: 0;
  padding-bottom: 0;
  width: 15%;
  float: left;

  dt.gallery-icon {
    padding: 0;
    img {
      padding: 8px;
      border: none !important;
    }
  }

  dd.gallery-caption {
    padding: 10px 0;
    text-align: justify;
  }
}