/*-----------------------------------------------------------------------------------*/
/*  About Section
/*-----------------------------------------------------------------------------------*/
@arrow_box_color: #e73a57;
.about-us {
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-header {
    margin-bottom: 50px;
    h2.title {
      color: @anchor_color;
    }
  }

}

.innards {
  text-align: center;
  margin-bottom: 50px;
  h3 {
    margin-bottom: 25px;
    font-weight: 600;
  }
  .icon-container {
    position: relative;
    width: 130px;
    min-height: 130px;
    height: auto !important;
    height: 130px;
    text-align: center;
    color: @white;
    background: @arrow_box_color;
    margin: 0 auto 42px;
    i {
      display: block;
      line-height: 130px;
    }
    &:after {
      position: absolute;
      top: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      pointer-events: none;
      border-top-color: @arrow_box_color;
      border-width: 9px;
      left: 50%;
      margin-left: -9px;
    }
  }
}

span.line-vertical {
  background: #eee6e4;
  display: block;
  width: 1px;
  height: 55px;
  margin: 0 auto 35px;
}