/*-----------------------------------------------------------------------------------*/
/*  Widgets
/*-----------------------------------------------------------------------------------*/
.footer {
  a {
    transition: all ease-in-out .2s;
  }
}

.widget_categories, .widget_archive, .widget_recent_comments, .widget_recent_entries {
  ul {
    li {
      &:before {
        font-family: 'FontAwesome';
        content: "\f101";
        padding-right: 17px;

      }
    }
  }

}

.sidebar .widget {
  .title {
    color: #525659;
    text-transform: uppercase;
    font-weight: 600;
  }
  ul {
    li {
      border-bottom: 1px solid #d3dae0;
      padding: 6px 0 6px 0;
      font-size: 16px;
      color: #6e7375;

      a {
        color: @heading_color;
        color: #6e7375;
        &:hover {
          color: @anchor_color;
        }
      }
      ul {
        padding: 6px 0 0;
        li {
          &:first-child {
            border-top: 1px solid #dedede;
          }
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.widget {
  margin-bottom: 80px;
  .title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 22px;
  }
  ul {
    list-style: none;
    margin: 0;
    li {
      ul {
        li {
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .gallery-post-slider ul li {
    border: none;
    background: none;
    padding: 0;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Search Form Widget
/*-----------------------------------------------------------------------------------*/
.searchform {
  overflow: hidden;
  background-color: #f16543;

  ::-webkit-input-placeholder {
    color: @white;
  }
  ::-moz-placeholder {
    color: @white;
  }
  /* firefox 19+ */
  :-ms-input-placeholder {
    color: @white;
  }
  /* ie */
  input:-moz-placeholder {
    color: @white;
  }

  #s, #searchsubmit {
    color: @white;
    height: 48px;
    border: none;
    background-color: @gris_clair;
  }
  #s {
    float: left;
    width: 85%;
    padding-left: 20px;
    padding-right: 0;
  }
  .screen-reader-text {
    display: none;
  }
  #searchsubmit {
    float: right;
    width: 15%;
    padding-left: 0;
    padding-right: 0;
    background-image: url("../images/icon-lense.png");
    background-repeat: no-repeat;
    background-position: center center;
    &:hover {
      background-color: @heading_color;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Testimonial Widget
/*-----------------------------------------------------------------------------------*/
.widget {
  blockquote {
    margin: 0;
    padding: 5px;
    border: 0;
    p {
      font-size: 14px;
      line-height: 23px;
    }
    strong {
      color: @heading_color;
      margin-left: 10px;
    }
  }
}

.footer {
  .widget {
    blockquote {
      strong {
        color: #8CCFCC;
      }
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Tabbed Widget
/*-----------------------------------------------------------------------------------*/
.widget {
  .tabbed {
    .tabs {
      margin: 0;
      padding: 0;
      li {
        float: left;
        list-style: none;
        margin: 0;
        background-image: none;
        border: none;
        padding: 10px 1px 10px;
        width: 33.333%;
        color: #878c90;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        border-right: 1px solid #d3dae0;
        &:last-child {
          border-right: none;
        }
        &:hover, &.current {
          color: @white;
          background-color: #f16543;
          border-right: 1px solid #f16543;
          border-bottom: 1px solid #f16543;
          margin-bottom: -1px;
        }
      }
    }

    .block {
      margin: 0;
      display: none;
    }

    .block.current {
      display: block;
      padding: 32px 0 0;
      border-top: 1px solid #d3dae0;
    }
    .widget-list li {
      background: none;
      border-bottom: 1px solid #E8E9EF;
      padding: 0 0 20px;
      margin-bottom: 25px;
      .link-read-more {
        color: #f16543;
        &:hover {
          color: @heading_color;
          &:after {
            color: @heading_color;
          }
        }
      }
    }
  }
}

.widget-list {
  li {
    overflow: hidden;
    margin-bottom: 20px;
    &:last-child {
      border-bottom: none;
    }
    h5 {
      margin: 0 0 9px;
      line-height: 21px;
      color: #a5aaae;
      font-size: 13px;
      font-weight: normal;
    }
    figure {
      float: left;
      margin-right: 22px;
      img {
        width: 102px;
        height: 104px;
      }
    }
    span {
      display: inline-block;
      font-size: 12px;
      padding-left: 20px;
      background-repeat: no-repeat;
      background-position: left 3px;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    .comments {
      background-image: url("../images/icon-chat-3.png");
      background-position: left 3px;
      margin-right: 18px;
    }
    .author {
      background-image: url("../images/icon-user.png");
      background-position: left 3px;
      padding-left: 17px;
    }
    .link-read-more {
      text-transform: uppercase;
      font-size: 12px;
      display: block;
      &:after {
        content: " \f101";
        font-family: FontAwesome;
        color: @anchor_color;
      }
    }
  }
}

.footer {
  .widget {
    .tabbed {
      .tabs li {
        font-size: 13px;
        border-color: #44494E;
      }
      .block {
        border-color: #44494E;
      }
    }

    .widget-list {
      li {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-color: #44494E;
        &:last-child {
          border-bottom: none;
        }
        .wrapper {
          float: left;
          width: 70%;
          padding-left: 15px;
        }
        h5 {
          line-height: 18px;
          a {
            color: #CDD1D4;
            &:hover {
              color: @white;
            }
          }
        }
        figure {
          margin: 0;
          width: 30%;
        }
        img {
          max-width: 100%;
          height: auto;
        }
        span {
          a {
            color: #7D8489;
          }
        }
        .comments,
        .author {
          background-position: left 4px;
        }
        .link-read-more {
          color: #8CCFCC;
          text-transform: none;
          font-size: 11px;
          &:after {
            content: "";
          }
          &:hover {
            color: @white;
          }
        }
      }
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  About Us Widget
/*-----------------------------------------------------------------------------------*/
.widget.widget_text {

  figure {
    margin-bottom: 25px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

}

/*-----------------------------------------------------------------------------------*/
/*  Recent Posts Widget
/*-----------------------------------------------------------------------------------*/
.widget.widget_recent_entries {
  .blog-icon {
    background: @anchor_color;
    display: inline-block;
    float: left;
    width: 50px;
    height: 50px;
    margin: 0 15px 15px 0;
    text-align: center;
    i {
      line-height: 50px;
      color: #ffffff;
      transition: .2s all ease-in-out;

    }
    &:hover {
      background: #ffffff;
    }
    &:hover {
      i {
        color: @anchor_color;
      }
    }
  }
  ul li {
    overflow: hidden;
    margin-bottom: 15px;
    background: none;
    padding-left: 0;
  }
  h5 {
    font-size: 13px;
    font-weight: normal;
    margin: 5px 0;
    a {
      color: #cdd1d4;
      &:hover {
        color: lighten(#cdd1d4, 25%);
      }
    }
  }
  .post-meta {
    font-size: 12px;
    font-style: italic;
    font-family: 'Open Sans', sans-serif;
  }

}

/*-----------------------------------------------------------------------------------*/
/*  Twitter Widget
/*-----------------------------------------------------------------------------------*/
@twitter_anchor_color: #8ccfcc;
.widget_displaytweetswidget {
  p {
    border-bottom: 1px solid #44494e;
    padding-bottom: 12px;
    margin-bottom: 10px;
    line-height: 21px;
  }

  a {
    color: @twitter_anchor_color;
    &:hover {
      color: lighten(@twitter_anchor_color, 25%);
    }
  }

  small {
    font-size: 12px;
  }

  .theme-btn {
    margin-top: 13px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.footer .widget_displaytweetswidget {
  p {
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.sidebar .widget_displaytweetswidget {
  p {
    border-bottom-color: #ecf0f3;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  a {
    color: #7d8489;
    &:hover {
      color: @anchor_color;
    }
  }
  small {
    font-size: 13px;
    color: #4b4b4b;
    display: inline-block;
    margin-top: 10px;
  }
  .theme-btn {
    color: #a9b3bc;
    font-size: 14px;
    border: 2px solid #ced4d9;
    padding: 9px 21px;
    &:after {
      content: " \f099";
      font-family: FontAwesome;
      color: #a9b3bc;
      font-size: 18px;
      margin-left: 9px;
    }
    &:hover {
      color: @white;
      border-color: #f16543;
      background-color: #f16543;
      &:after {
        color: @white;;
      }
    }

  }
}

.interact{
  display: none;
}
#twitter-fetcher{
  p{
    border: none;
    padding-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    line-height: 23px;
  }
  .tweet{
    margin-bottom: 0;
  }
  li{
    border-bottom: 1px solid #44494e ;
    margin-bottom: 12px;
    &:last-child{
      border-bottom: none;
    }
  }
}
.timePosted{
  font-style: italic;
  font-size: 12px;
}

/*-----------------------------------------------------------------------------------*/
/* flicker widget
/*-----------------------------------------------------------------------------------*/

.widget_flicker{
  .flickr_badge_image{
    float: left;
    border: 3px solid transparent;
  }
}
#myElement {
  margin-top: -8px;
  margin-left: -8px;
  a {
    margin: 8px !important;
    transition: .2s all ease-in-out;
    &:hover{
      opacity: .7;
    }
    img{
      height: auto !important;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Contact Us Widget
/*-----------------------------------------------------------------------------------*/
.widget.contact {
  h5 {
    color: #a5a9ad;
    font-size: 13px;
    margin-bottom: 30px;
    font-weight: normal;
  }
  address {
    margin-bottom: 27px;
    line-height: 21px;
  }
  .contacts-list {
    padding: 0;
    text-align: left;
    li {
      font-size: 13px;
      display: block;
      border-bottom: 1px solid #44494e;
      width: auto;
      margin: 9px 0;
      padding: 0 0 9px;
      background: none;
      &:last-child {
        border-bottom: none;
      }
      span {
        color: #a5a9ad;
      }
      a {
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}

.sidebar .widget.contact {
  .contacts-list li {
    border-color: #ecf0f3;
  }

  h5,
  a,
  .contacts-list li,
  .contacts-list li span {
    color: #7D8489;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Wp Calendar Widget
/*-----------------------------------------------------------------------------------*/
#wp-calendar {
  width: 100%;
  caption {
    margin: 11px 0 30px;
    text-align: left;
    font-size: 24px;
    line-height: 30px;
    color: @heading_color;
    font-weight: 500;
  }
  thead th {
    color: @white;
    border: 1px solid #DCDCDC;
    text-align: center;
    padding: 10px 4px;
    background: @heading_color;
  }
  tbody {
    color: @white;
    td {
      background: @anchor_color;
      border: 1px solid @white;
      text-align: center;
      padding: 10px 4px;
      &#today,
      &:hover {
        background: @heading_color;
      }
      a {
        color: @white;
      }
    }
    .pad {
      background: none;
    }
  }
}

.footer {
  #wp-calendar {
    caption {
      color: @footer_widget_title_color;
    }
    thead th {
      border-color: #36384C;
    }
    tbody {
      td {
        border-color: #36384C;
      }
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  Tag Cloud Widget
/*-----------------------------------------------------------------------------------*/
.widget {
  .tagcloud {
    overflow: hidden;
    margin-left: -4px;
    margin-right: -4px;
    a {
      display: block;
      float: left;
      padding: 6px 15px;
      color: #84898b;
      background-color: #ecf0f3;
      font-size: 14px;
      margin: 4px;
      text-decoration: none;
      &:hover {
        color: @white;
        background: @anchor_color;
      }
    }
  }
}

.footer {
  .widget .tagcloud a {
    color: @white;
    background-color: #f16543;
    &:hover {
      color: @heading_color;
      background-color: #8CCFCC;
    }
  }
}