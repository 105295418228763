/*-----------------------------------------------------------------------------------*/
/*  Features Section
/*-----------------------------------------------------------------------------------*/
.features {
  .container {
    padding-top: 0;
    padding-bottom: 100px;
  }
}

.standout {
  h4 {
    margin-bottom: 0;
  }
  h2 {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .theme-btn {
    color: @main_color;
    font-size: 18px;
    font-weight: 800;
    margin-top: 20px;
    padding: 15px 41px;
    border: 3px solid @main_color;
    &:hover {
      color: @white;
      background-color: @main_color;
    }
  }
}

.features-list {
  padding-top: 5px;
  li {
    overflow: hidden;
    margin-bottom: 18px;
    .icon-container {
      float: left;
      width: 68px;
      height: 66px;
      margin: 0 17px 15px 0;
      background-color: #919292;
      color: @white;
      text-align: center;
      .theme-transition;
      i {
        display: block;
        line-height: 66px;
      }
    }
    h4 {
      margin-bottom: 8px;
    }
    p {
      line-height: 21px;
    }
  }
}

.features.variant-two {
  .container {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  background-color: #e9eef1;

  .standout .theme-btn {
    font-weight: 700;
    border-color: @heading_color;
    &:hover {
      color: @white;
      background-color: @heading_color;
    }
  }

  .features-list li .icon-container {
    .square(67px);
    .border-radius(50%);
  }
  .features-list li .icon-container i {
    line-height: 67px;
  }
}

.features.variant-three {
  background-color: @white;

  .container {
    padding-top: 100px;
  }

  .features-list {
    padding-top: 0;
    .icon-container {
      float: left;
      .square(67px);
      .border-radius(50%);
      margin: 0 17px 15px 0;
      background-color: #f16543;
      color: @white;
      text-align: center;
      i {
        display: block;
        line-height: 67px;
      }
      &:hover {
        background-color: @white;
        border: 1px solid @anchor_color;
        i {
          color: #f16543;
        }
      }
    }
    .content {
      display: table;
    }
  }
}

.features.variant-four {
  background-color: #fbf8f7;

  .container {
    padding-top: 66px;
  }

  .standout {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      color: #524b49;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 22px;

    }
    h4 {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 7px;
    }
    p,
    h4 {
      color: #9d9897;
    }
    p {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .features-list {
    margin: 0 0 30px;
    padding-top: 0;
    i {
      color: #a09a98;
      margin-right: 5px;
      width: 20px;
    }
    p {
      line-height: 20px;
    }

    h4 {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 24px;
    }

    .heart, .magic, .mobile {
      padding-left: 25px;
      &:before {
        position: absolute;
        left: 0;
        font-family: 'FontAwesome';
        color: #a09a98;
      }
    }
    .heart {
      &:before {
        content: "\f004";
      }
    }
    .magic {
      &:before {
        content: "\f0d0";
      }
    }
    .mobile {
      &:before {
        content: "\f10b";
      }
    }
  }
  .theme-btn {
    margin-top: 35px;
    border: 1px solid #e5dfde;
    color: #f16543;
    padding: 13px 42px;
    font-weight: 600;
    &:hover {
      background-color: #e5dfde;
    }
  }
}



